// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Argon
@import 'argon';

@import 'dental/card';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body{
    background: #f9f9f9;
    font-family: 'Inter', sans-serif;
}

.form-control, .alert, .btn{
    border-radius: 1.5rem;
}

.logo{
    text-align: center;
    max-width: 250px;
    margin: 50px 0;
}

@media(max-width: 480px){
    .signaturePad{
        max-width: 320px;
    }
    .acoes{
        .btn{
            width: 100%;
            &:first-child{
                margin-bottom: 1rem;
            }
        }
    }
}

@media(min-width: 1100px){
    .container-fluid{
        padding-left: 100px;
        padding-right: 100px;
    }
}

.mt-2rem {
    margin-top: 2rem !important;
}

body,
input { color: #333; }
label { font-weight: bold; }

.ft-1rem {
    font-size: 1.2rem;
}
